import { useContext, useEffect, useState } from "react";
import { changeTrackingPointMarker, renderMarker } from "./sp-marker.controller";
import { SurveyPointType } from "app/modules/survey/survey.interfaces";
import SurveyStore from "app/modules/survey/survey.context";

interface Props {
  point: SurveyPointType;
  zoom: number;
  hasLabels: boolean;
  map: google.maps.Map;
}

const SPMarker = ({ point, map, zoom, hasLabels }: Props) => {
  const SurveyContext = useContext(SurveyStore);
  const [marker, setMarker] = useState<google.maps.Marker | undefined>();

  useEffect(() => {
    renderMarker(SurveyContext, point, map, setMarker, marker);
    return () => {
      marker?.setMap(null);
    };
  }, [])

  useEffect(() => {
    if (point) changeTrackingPointMarker({ marker, point, map, hasLabels });
    else marker?.setMap(null);
  }, [point, zoom, hasLabels]);

  useEffect(() => {
    return () => {
      marker?.setMap(null);
    };
  }, [marker]);

  return null;
};

export default SPMarker;